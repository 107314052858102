define([], () => {

  const profilesDropdownInput = () => {

    const toUppercase = str => str.toUpperCase();

    const component = {};

    const _classes = {
      input: 'dropdownInput_input',
      button: 'profilesDropdownInput_button',
      icon: 'profilesDropdownInput_icon',
    };
    component.init = (element) => {
      component.element = element;

      // select all children from _classes
      component.children = {};
      for (const [key, cls] of Object.entries(_classes)) {
        component.children[key] = component.element.querySelector(`.${cls}`);
      }

      component.attributeName = component.element.getAttribute('data-attributeName');

      component._addListeners();

      component._showButton();

      component.children.input.placeholder = component.attributeName + ' A-Z';

      return component;
    };

    component._addListeners = () => {
      component.children.button.addEventListener('click', () => {
        component.children.input.focus();
      });

      component.children.input.addEventListener('focus', () => {
        component._showIcon();
        component.children.input.placeholder = 'Search ' + component.attributeName + '...';
      });
      component.children.input.addEventListener('blur', () => {
        component._showButton();
        component.children.input.placeholder = component.attributeName + ' A-Z';
      });
    };

    component._showButton = () => {
      component.children.button.style.display = '';
      component.children.icon.style.display = 'none';
    };

    component._showIcon = () => {
      component.children.button.style.display = 'none';
      component.children.icon.style.display = '';
    };

    return component;
  };

  return profilesDropdownInput;
});
